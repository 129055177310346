import * as React from "react"
import Pricing from '../components/Pricing'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Contact from '../components/Contact'


const IndexPage = () => (
  <Layout>
    <SEO title="Shuttle Service" />
    <Pricing />
    <Contact />
   
  </Layout>
)

export default IndexPage
