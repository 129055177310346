import React from 'react'

const Contact = () => {
    return (
        <div className='contact-box'>
            <div className='contact-80' id='contact-id'>
                <h2 className='contact-title'>Contact Us</h2>
                <p>Contact Us For Custom Car Rental Service</p>
            </div>
            <div className='form-box'>
            
            <form class="form" id="form1" action="https://submit-form.com/j6AsXdr8" target="_self">
                <input
                  type="hidden"
                  name="_redirect"
                  value="https://rent.travelcebu.ph/thanks"

                />
                <p className="name">
                  <input name="name" type="text" className="validate[required,custom[onlyLetter],length[0,100]] feedback-input" placeholder="Name" id="name" required />
                </p>

                <p className="email">
                  <input name="email" type="text" className="validate[required,custom[email]] feedback-input" id="email" placeholder="Email" required/>
                </p>

                <p className="Phone Number">
                    <input name="phone" type="tel" className="validate[required,custom[tel]] feedback-input" id="title" placeholder="Phone Number" />
                  </p>
                <p className="text">
                  <textarea name="text" className="validate[required,length[6,300]] feedback-input" id="comment" placeholder="Message"></textarea>
                </p>
            
                <p>
                    <input type="submit" value="SEND" id="button-blue"/>
                </p>
                
                 
                
              </form>

            </div>
            
        </div>
    )
}

export default Contact
