import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const query = graphql`
  {
    allCardDetailsJson {
      nodes {
        id
        title
        image {
          childImageSharp {
            gatsbyImageData(
                width: 700
                height: 400
            )
          }
        }
        pricingDay
        pricingWeek
        pricingMonth
        desc
        capacity
        details
        button
      }
    }
  }
`


const Pricing = () => {
    
    const { allCardDetailsJson: { nodes } } = useStaticQuery(query)

    return (
        <>
            <div className='box-80'>
                <h2 className='title'>Pricing</h2>
                <div className="line"></div>
            </div>
            <div className="box-80-flex">
                {nodes.map((data) => {
                    const { id, title, image, pricingDay, pricingWeek, pricingMonth, desc, capacity, details,  button } = data
                    return (
                        <div className="col-3-card" key={id}>
                            <h4 className='inner-card-heading'>{title}</h4>
                            <div className='image-box'>
                                <GatsbyImage image={image.childImageSharp.gatsbyImageData} />
                            </div>
                        
                            <div className='box-price'>
                                <h4 className='price-card'>P {pricingDay} /day</h4>
                                <h4 className='price-card'>P {pricingWeek} /week</h4>
                                <h4 className='price-card'>P {pricingMonth} /month</h4>
                                
                            </div>
                            <p className='well'>{desc}</p>
                            <div className='price-line'></div>
                            <div className='box-details'>
                                <p className='p-details'>{capacity}</p>
                                {details.map((item, index) => {
                                    return (
                                        <p key={index} className='p-details'>{item}</p>
                                    )
                                })}
                            </div>
                            <div className='box-btn'>
                                <a href="#contact-id">
                                    <button className="btn-select">
                                        {button}
                                    </button>
                                </a>
                                
                            </div>
                            
                        </div>
                    )
                })}
            </div>

            
        </>
    )
}

export default Pricing
